import React from 'react';

import { Icon } from 'components';

const ReviewSections = () => (
  <div className="info-block">
    <p className="info-block__title">
      <span className="info-block__num">
        <Icon name="verified-house" width="24" height="24" />
      </span>
      <span>Please review the following sections and verify the information is correct. </span>
    </p>
  </div>
);

export default React.memo(ReviewSections);
