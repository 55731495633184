import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button, Icon, Container, Loader } from 'components';
import useLayout from 'hooks/useLayout';
import { styled } from 'styles';
import ListingInfo from './ListingInfo';
import SellProcessContext from '../context';
import IdentifyForm from './IdentifyForm';
interface IdentifyProps {
  open: boolean;
}

const Identify: React.FC<IdentifyProps> = ({ open }) => {
  const layout = useLayout();
  const {
    loading,
    mlsListingLoading,
    listing,
    listingSelected,
    setListingSelected,
    setActiveTab,
    identifyTabData,
    setIdentifyTabData,
  } = useContext(SellProcessContext);
  const [showListing, setShowListing] = useState(true);

  const selectProperty = () => {
    if (!listing) return;
    setIdentifyTabData({
      id: listing.id,
      address1: listing.address1,
      address2: listing.address2,
      city: listing.city,
      state: listing.state,
      postalCode: listing.postalCode,
      images: listing.images
    });
    setActiveTab('info');
    setShowListing(false);
    setListingSelected(true);
  };

  const handleSubmit = value => {
    setIdentifyTabData(value);
    setActiveTab('info');
  };

  useEffect(() => {
    if (listing && !listingSelected) setShowListing(true);
  }, [listing, listingSelected]);

  const loader = (
    <LoaderContainer>
      <Loader>
        <br /><br />We're searching your property
      </Loader>
    </LoaderContainer>
  );

  return (
    <StyledIdentify className={clsx({ open })}>
      {open && !showListing && (
        <Container header>
          <Icon name="rocket-small" width="24" height="24" />
          Please enter the correct information for your property.
        </Container>
      )}
      <Container
        header
        number={1}
        active={open}
        merged={(open && !showListing) || (!open && Boolean(identifyTabData)) ? 'top' : undefined}>
        Identify Property
        <Button link onClick={() => setActiveTab('identify')}>
          Edit
        </Button>
      </Container>
      {identifyTabData && !open && (
        <Container className="property-data" merged="bottom">
          <div className="listing-title">
            {identifyTabData?.address1}
            {identifyTabData?.address2 ? `, ${identifyTabData.address2}` : ''}
            {identifyTabData.id && (
              <span className="verified">
                <span className="circle">
                  <Icon name="check" size={20} />
                </span>
                Verified
              </span>
            )}
          </div>
          <div>
            {identifyTabData?.address2} {identifyTabData?.city}, {identifyTabData?.state}{' '}
            {identifyTabData?.postalCode}
          </div>
        </Container>
      )}
      {open &&
        (showListing ? (
          <>
            <div className="info-block">
              <p className="info-block__title">
                <span className="info-block__num">
                  <Icon name="hand" width="24" height="24" />
                </span>
                <span>Hey! Looks like we’ve found your property! Is this your home?</span>
              </p>
            </div>
            {mlsListingLoading && loader}
            {listing && <ListingInfo data={listing} className="info-block listing-info" />}
            {layout !== 'mobile' && (
              <>
                <div className="info-block info-block_merged-top">
                  <p className="info-block__title">
                    <span className="info-block__num">
                      <Icon name="happy" width="24" height="24" />
                    </span>
                    <span>Yes! This is my home!</span>
                  </p>
                  <Button className="info-block__btn" primary outline onClick={selectProperty}>
                    Yes
                  </Button>
                </div>
                <div className="info-block info-block_merged-bottom">
                  <p className="info-block__title">
                    <span className="info-block__num">
                      <Icon name="sad" width="24" height="24" />
                    </span>
                    <span>No! This is not my property.</span>
                  </p>
                  <Button
                    className="info-block__btn"
                    primary
                    outline
                    onClick={() => setShowListing(false)}>
                    No
                  </Button>
                </div>
              </>
            )}
            {layout === 'mobile' && (
              <Container className="info-block-btns">
                <Button className="info-block__btn" primary outline onClick={selectProperty}>
                  <Icon name="happy" width="20" height="20" />
                  Yes! This is my home!
                </Button>
                <Button
                  className="info-block__btn"
                  primary
                  outline
                  onClick={() => setShowListing(false)}>
                  <Icon name="sad" width="20" height="20" />
                  No! This is not my property.
                </Button>
              </Container>
            )}
          </>
        ) : (
            !loading && (
              <Container merged="bottom">
                <IdentifyForm defaultValues={identifyTabData} onSubmit={handleSubmit} />
              </Container>
            )
          ))}
    </StyledIdentify>
  );
};

const StyledIdentify = styled.div`
  width: 100%;
  margin-bottom: 20px;

  .info-block-btns {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    .button {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 21px;
      .icon {
        fill: #000;
        margin-right: 12px;
      }
      &:first-of-type {
        margin-bottom: 16px;
      }
    }
  }

  .property-data {
    flex-direction: column;
    align-items: flex-start !important;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 20px 12px 47px;

    .listing-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 8px;
    }
  }

  .verified {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    color: #4069b1;
    margin-left: 20px;
    font-weight: normal;

    .circle {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      background: #4069b1;
      border-radius: 20px;
      margin-right: 8px;

      .icon {
        fill: #fff;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .property-data {
      .listing-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;


const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(218, 223, 225, 0.75);
  color: #000;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
`;

export default Identify;
