/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import MaskedInput from 'react-input-mask';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import dayjs from 'dayjs';

import { REGEXP } from 'consts';
import { Button, RadioButton, Input, CurrencyInput, Checkbox, DateInput } from 'components';
import financialSchema from '../formSchema/financialSchema.json';

const STEPS_NUMBER = 7;

const getRadioGroup = (step, props) => {
  if (step < 1) return null;
  const { radioFields } = financialSchema;
  const displayRadioInputs = Object.keys(radioFields).slice(0, step * 5);

  return displayRadioInputs.map(key => (
    <div className="process-form__radio-block" key={key}>
      <h3 className="process-form__title">{radioFields[key].label}</h3>
      {radioFields[key].enum.map((item, index) => (
        <RadioButton
          defaultChecked={item === 'Yes'}
          label={item}
          value={item}
          id={`${key}[${index}]`}
          name={key}
          key={`${key}[${index}]`}
          {...props}
        />
      ))}
    </div>
  ));
};

const formatDate = value => {
  return dayjs(value).format('MM/DD/YY');
};

interface FinancialsFormProps {
  defaultValues?: { [key: string]: any };
  onSubmit: (values: { [key: string]: any }) => void;
}

const FinancialsForm: React.FC<FinancialsFormProps> = ({ defaultValues, onSubmit }) => {
  const [step, setStep] = useState(0);
  const { register, control, errors, handleSubmit } = useForm({
    defaultValues
  });
  const { fields: sellers, append } = useFieldArray({
    control,
    name: 'sellers'
  });
  const hasErrors = Object.keys(errors).length > 0;

  if (sellers.length === 0) {
    append({
      name: defaultValues?.name,
      email: defaultValues?.email
    });
  }

  const handleContinue = values => {
    if (hasErrors) return;
    if (step < STEPS_NUMBER) setStep(step + 1);
    else onSubmit(values);
  };

  const onAddField = e => {
    e.preventDefault();
    append({});
  };

  return (
    <form className="process-form" onSubmit={handleSubmit(handleContinue)}>
      <div className="process-form__input-block process-form__input-block_short">
        <Controller
          control={control}
          rules={{
            required: 'Required'
          }}
          name="desired_price"
          defaultValue=""
          render={controllerProps => (
            <CurrencyInput
              label="Desired Price*"
              placeholder="Enter price"
              min={0}
              error={errors.desired_price}
              className="process-form__input"
              {...controllerProps}
            />
          )}
        />
      </div>
      <h3 className="process-form__title seller-title">Seller Info:</h3>
      {sellers.map((item, index) => (
        <div className="process-form__input-block seller-inputs" key={`sellers[${index}]`}>
          <Input
            ref={register({
              pattern: {
                value: REGEXP.NAME,
                message: 'Invalid name'
              }
            })}
            defaultValue={item.name}
            id={`sellers[${index}].name`}
            name={`sellers[${index}].name`}
            error={errors.sellers?.[index]?.name}
            className="process-form__input"
            label="Name"
            placeholder="Enter Name"
          />
          <Input
            ref={register({
              pattern: {
                value: REGEXP.EMAIL,
                message: 'Invalid email address'
              }
            })}
            defaultValue={item.email}
            id={`sellers[${index}].email`}
            name={`sellers[${index}].email`}
            error={errors.sellers?.[index]?.email}
            className="process-form__input"
            label="Email"
            placeholder="Enter Email"
          />
        </div>
      ))}
      <div className="info-block__btn-block info-block__btn-block_add">
        <Button
          className="info-block__btn info-block__btn-add"
          primary
          outline
          onClick={onAddField}>
          + Add Seller
        </Button>
      </div>
      <h3 className="process-form__title">Listing Dates</h3>
      <div className="process-form__input-block process-form__input-block_short">
        <Controller
          control={control}
          rules={{
            required: 'Required date',
            validate: value => dayjs(value).isValid() || `Invalid date`
          }}
          name="start_listing_earlier"
          render={({ onChange, ...controllerProps }) => (
            <DateInput
              inputProps={{
                label: 'Start listing not earlier than*',
                placeholder: 'MM/DD/YY',
                error: errors.start_listing_earlier
              }}
              onChange={value => onChange(formatDate(value))}
              className="process-form__input"
              {...controllerProps}
            />
          )}
        />
      </div>

      {/* *******end of step 1******* */}
      {step > 0 && (
        <div className="process-form__step">
          <div className="process-form__input-block">
            <Controller
              control={control}
              name="pin"
              rules={{
                validate: value => !value.includes('_') || 'Invalid PIN'
              }}
              defaultValue=""
              render={controllerProps => (
                <Input
                  className="process-form__input"
                  as={MaskedInput}
                  mask="99-99-999-999-9999"
                  error={errors.pin}
                  label="PIN"
                  placeholder="Enter PIN"
                  {...controllerProps}
                />
              )}
            />
            <Controller
              control={control}
              name="last_year_taxes"
              defaultValue=""
              render={controllerProps => (
                <CurrencyInput
                  label="Last Year Taxes"
                  placeholder="Enter Last Year Taxes"
                  min={0}
                  className="process-form__input"
                  {...controllerProps}
                />
              )}
            />
          </div>
          <h3 className="process-form__title">Property Leased</h3>
          <div className="process-form__radio-block">
            {financialSchema.property_leased.enum.map((item, index) => (
              <RadioButton
                ref={register}
                defaultChecked={item === 'No'}
                value={item}
                label={item}
                name="property_leased"
                id={`property_leased[${index}]`}
                key={`property_leased[${index}]`}
              />
            ))}
          </div>
          <div className="process-form__input-block">
            <Input
              ref={register}
              name="electronic_account_number"
              className="process-form__input"
              label="Electronic Account Number"
              placeholder="Electronic Account Number"
            />
            <Input
              ref={register}
              name="gas_account_number"
              className="process-form__input"
              label="Gas Account Number"
              placeholder="Gas Account Number"
            />
          </div>
        </div>
      )}
      {/* *******end of step 1******* */}

      {/* *******beginning of step 2******* */}
      {step > 1 && (
        <div className="process-form__step">
          <div className="checkbox-wrapper">
            <div className="checkbox-section">
              <div className="checkbox-container">
                <div className="checkbox-container__title">Price Includes</div>
                <div className="checkboxes">
                  {Object.keys(financialSchema.price_includes).map(key => (
                    <Checkbox
                      ref={register}
                      name={key}
                      label={financialSchema.price_includes[key].label}
                      key={key}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="process-form__input-block">
            <Input
              ref={register}
              name="seller_transfers"
              className="process-form__input"
              label="Seller also transfers the following"
              placeholder="Enter text"
            />
            <Input
              ref={register}
              name="items_excluded"
              className="process-form__input"
              label="The following items are excluded"
              placeholder="Enter items"
            />
          </div>
        </div>
      )}
      {/* *******end of step 2******* */}

      {/* *******beginning of step 3******* */}
      <div className="process-form__step">{getRadioGroup(step - 2, { ref: register })}</div>
      {/* *******end of step 3******* */}

      <Button className="process-form__btn" primary>
        {step < STEPS_NUMBER ? 'Continue' : 'Next'}
      </Button>
    </form>
  );
};

export default FinancialsForm;
