import React, { useContext } from 'react';
import clsx from 'clsx';

import { styled } from 'styles';
import PropertyDetailsForm from './PropertyDetailsForm';
import ReviewSections from '../ReviewSections';
import SellProcessContext from '../context';

interface PropertyDetailsProps {
  open?: boolean;
}

const PropertyDetails: React.FC<PropertyDetailsProps> = ({ open }) => {
  const {
    listing,
    sellprocessId,
    setActiveTab,
    propertyInfoTabData,
    propertyDetailsTabData,
    setPropertyDetailsTabData
  } = useContext(SellProcessContext);
  const defaultFormValues = {
    property_description: listing?.description,
    parking_spaces: listing?.propertyDetails.parkingSpaces,
    year_built: listing?.propertyDetails.yearBuilt,
    ...propertyDetailsTabData
  };

  const onSubmit = values => {
    setPropertyDetailsTabData(values);
    setActiveTab('financials');
  };

  return (
    <StyledPropertyDetails>
      {open && <ReviewSections />}
      <div
        className={clsx('info-block', {
          'info-block_merged-top': open
        })}>
        <p className={clsx('info-block__title', { 'info-block__title_primary': open })}>
          <span className="info-block__num">3</span>
          <span>Property Details</span>
        </p>
        {!open && Boolean(propertyInfoTabData) && (
          <button className="info-block__edit-btn" onClick={() => setActiveTab('details')}>
            Edit
          </button>
        )}
      </div>

      {open && (
        <div className="info-block info-block_merged-bottom">
          <PropertyDetailsForm
            defaultValues={defaultFormValues}
            condo={
              propertyInfoTabData?.propertyType === 'Condo' &&
              propertyInfoTabData?.condoPUD === 'yes'
            }
            onSubmit={onSubmit}
            sellprocessId={sellprocessId}
          />
        </div>
      )}
    </StyledPropertyDetails>
  );
};

const StyledPropertyDetails = styled.div`
  .process-form {
    &__dropdown {
      .dropdown__menu {
        padding: 12px 0;
      }
    }
    &__dropdown-time {
      .dropdown__menu {
        max-height: 260px;
        overflow-y: auto;
      }
    }
  }
`;

export default PropertyDetails;
