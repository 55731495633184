import React from 'react';

interface PropertyInfoSummaryProps {
  data: { [key: string]: any };
  onEdit: (value: SellProcessTabKey) => void;
}

const PropertyInfoSummary: React.FC<PropertyInfoSummaryProps> = ({ data, onEdit }) => {
  return (
    <div className="review-wrapper">
      <div className="review-header">
        <h3 className="review-title">Property Information:</h3>
        <button className="info-block__edit-btn" onClick={() => onEdit('info')}>
          Edit
        </button>
      </div>
      <div className="review-block">
        <div className="review-info-block">
          <p className="review-text">
            <strong>My Property is for:</strong> {data.saleOrRent}
          </p>
          <p className="review-text">
            <strong>Type:</strong> {data.propertyType}
          </p>
          <p className="review-text">
            <strong>Bedrooms:</strong> {data.beds}
          </p>
          <p className="review-text">
            <strong>Bathrooms:</strong> {data.baths}
          </p>
          {data.propertyType === 'Condo' && (
            <>
              <p className="review-text">
                <strong>Condo PUD:</strong> {data.condoPUD}
              </p>
            </>
          )}
          <p className="review-text">
            <strong>Living area square feet:</strong> {data.sqft}
          </p>
          {data.condoPUD && (
            <>
              <p className="review-text">
                <strong>Lot Size:</strong> {data.lotSize}
              </p>
              <p className="review-text">
                <strong>Parking Number:</strong> {data.parkingNumber}
              </p>
              <p className="review-text">
                <strong>Parking Information</strong> {data.parkingInformation}
              </p>
              <p className="review-text">
                <strong>Storage Number</strong> {data.storageNumber}
              </p>
              <p className="review-text">
                <strong>Storage Information</strong> {data.storageInformation}
              </p>
            </>
          )}
        </div>
        {data.condoPUD && (
          <div className="review-info-block">
            <p className="review-text">
              <strong>Owner:</strong> {`${data.firstName} ${data.lastName}`}
            </p>
            <p className="review-text">
              <strong>Email:</strong> {data.email}
            </p>
            <p className="review-text">
              <strong>Phone Number:</strong> {data.phone}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PropertyInfoSummary;
