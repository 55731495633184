import React from 'react';
import IndexLayout from 'layouts';
import SellProcess from 'views/SellProcessPage';
import { Components } from 'sitedata';

const SellProcessPage = () => (
  <IndexLayout>
    <Components.Header />
    <SellProcess />
    <Components.Footer />
  </IndexLayout>
);

export default SellProcessPage;
