import React, { useContext, useMemo } from 'react';
import { Icon } from 'components';
import clsx from 'clsx';

import { styled } from 'styles';
import SellProcessContext from '../context';
import PropertyInfoForm from './PropertyInfoForm';

interface PropertyInfoProps {
  open: boolean;
}

const PropertyInfo: React.FC<PropertyInfoProps> = ({ open }) => {
  const {
    setActiveTab,
    listingSelected,
    listing,
    identifyTabData,
    propertyInfoInit,
    propertyInfoTabData,
    setPropertyInfoTabData
  } = useContext(SellProcessContext);
  const formDefaultValues = useMemo(() => {
    const propertyValues = {
      firstName: propertyInfoInit?.name,
      phone: propertyInfoInit?.phone,
      email: propertyInfoInit?.email
    };
    const listingValues =
      listing && listingSelected
        ? {
          propertyType: listing.propertyType,
          sqft: listing.propertyDetails.sqft,
          lotSize: listing.propertyDetails.lotSize,
          beds: listing.propertyDetails.beds,
          baths: listing.propertyDetails.baths
        }
        : undefined;
    return { ...listingValues, ...propertyValues, ...propertyInfoTabData };
  }, [listing, listingSelected, propertyInfoInit, propertyInfoTabData]);

  const onSubmit = values => {
    setPropertyInfoTabData(values);
    setActiveTab('details');
  };

  return (
    <StyledPropertyInfo>
      {open && (
        <div className="info-block">
          <p className="info-block__title">
            <span className="info-block__num">
              <Icon name="verified-house" width="24" height="24" />
            </span>
            <span>
              Please review the following sections and verify the information is correct.{' '}
            </span>
          </p>
        </div>
      )}

      <div
        className={clsx('info-block', {
          'info-block_merged-top': open || Boolean(propertyInfoTabData)
        })}>
        <p className={clsx('info-block__title', { 'info-block__title_primary': open })}>
          <span className="info-block__num">2</span>
          <span>Property Information</span>
        </p>
        {!open && Boolean(propertyInfoTabData) && (
          <button className="info-block__edit-btn" onClick={() => setActiveTab('info')}>
            Edit
          </button>
        )}
      </div>
      {!open && propertyInfoTabData && (
        <div className="info-block info-block_merged-bottom submit-data">
          <div className="submit-data__title">
            {propertyInfoTabData.saleOrRent === 'sale' ? 'For Sale' : 'For Rent'}
          </div>
          <div>
            {propertyInfoTabData.propertyType} {propertyInfoTabData.beds} Bedrooms{' '}
            {propertyInfoTabData.baths} Bathrooms
          </div>
        </div>
      )}

      {open && (
        <div className="info-block info-block_merged-bottom">
          <PropertyInfoForm defaultValues={formDefaultValues} onSubmit={onSubmit} />
        </div>
      )}
    </StyledPropertyInfo>
  );
};

const StyledPropertyInfo = styled.div`
  width: 100%;

  .submit-data {
    padding: 12px 20px 12px 47px;
    flex-direction: column;
    align-items: flex-start;

    .submit-data__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 8px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    div.label {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 14px;
      font-weight: 500;
    }

    .process-form__btn {
      margin-left: auto;
    }
  }
`;

export default PropertyInfo;
