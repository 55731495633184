import React, { useContext, useState } from 'react';
import clsx from 'clsx';

import { styled } from 'styles';
import { Button, Icon, Checkbox } from 'components';
import SellProcessContext from '../context';
import IdentifySummary from './IdentifySummary';
import PropertyInfoSummary from './PropertyInfoSummary';
import PropertyDetailsSummary from './PropertyDetailsSummary';
import FinancialsSummary from './FinancialsSummary';

interface ReviewProps {
  open?: boolean;
}

const Review: React.FC<ReviewProps> = ({ open }) => {
  const {
    identifyTabData,
    propertyInfoTabData,
    propertyDetailsTabData,
    financialsTabData,
    setActiveTab,
    onFormSubmit
  } = useContext(SellProcessContext);
  const [reviewed, setReviewed] = useState<boolean>(false);

  if (!open) return null;

  return (
    <StyledReview>
      <div className="info-block">
        <p className="info-block__title">
          <span className="info-block__num">
            <Icon name="verified-house" width="24" height="24" />
          </span>
          <span>Please review and submit form</span>
        </p>
      </div>

      <div
        className={clsx('info-block', {
          'info-block_merged-top': open
        })}>
        <p className={clsx('info-block__title', { 'info-block__title_primary': open })}>
          <span className="info-block__num">5</span>
          <span>Review</span>
        </p>
      </div>

      {open && (
        <div className="info-block info-block_merged-bottom">
          <div className="process-form">
            {/* Identify Summary */}
            {identifyTabData && (
              <IdentifySummary
                data={{ ...identifyTabData, price: financialsTabData?.desired_price }}
                onEdit={setActiveTab}
              />
            )}

            {/* Property Information */}
            {propertyInfoTabData && (
              <PropertyInfoSummary data={propertyInfoTabData} onEdit={setActiveTab} />
            )}

            {/* Property Details */}
            {propertyDetailsTabData && (
              <PropertyDetailsSummary data={propertyDetailsTabData} onEdit={setActiveTab} />
            )}

            {/* Financials */}
            {financialsTabData && (
              <FinancialsSummary data={financialsTabData} onEdit={setActiveTab} />
            )}

            <div className="submit-block">
              <Checkbox
                checked={reviewed}
                onChange={() => setReviewed(v => !v)}
                label="I have reviewed and agreed to all above"
              />
              <Button
                className="process-form__btn"
                primary
                disabled={!reviewed}
                onClick={onFormSubmit}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      )}
    </StyledReview>
  );
};

const StyledReview = styled.div`
  .submit-block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .process-form__btn {
      margin: 0;
    }
  }
  .review-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 24px;
  }
  .review-wrapper {
    border-bottom: 1px solid #c4c4c4;
    margin: 0 0 32px;
  }
  .review-header {
    display: flex;
    justify-content: space-between;
  }
  .review-block {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 32px;
    padding: 0 0 32px;
    flex: 1;
    .review-block:last-of-type {
      padding: 0;
    }
  }
  .review-info-block {
    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 12px;
    }
  }
  .review-text {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 8px;
    font-weight: normal;
    strong {
      font-weight: 500;
    }
  }
  .choices-info {
    padding: 0 0 32px;
  }
  .choice-item {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #9c9c9e;
    margin: 0 0 8px;
    strong {
      font-weight: 500;
      color: black;
    }
  }
  .property-price {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
  }
  .property-block {
    flex: 1;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    display: flex;
    img {
      width: 24px;
      height: 24px;
      border-radius: 3px;
      margin-right: 12px;
      object-fit: cover;
    }
    span {
      font-size: 16px;
      line-height: 24px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .review-block {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
    .property-block {
      &:first-of-type {
        margin: 0 0 32px;
      }
    }
    .header-property-info {
      .review-title {
        margin: 0 0 16px;
      }
    }
    .submit-block {
      flex-direction: column;
      .checkbox {
        margin: 0 0 24px;
      }
    }
  }
`;

export default Review;
