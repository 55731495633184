import { urlEncodeBody } from 'utils';
import { fetchWithToken } from 'api';

//
export async function submitSellProcessForm(values, sellProcessId: string): Promise<void> {
  const data = {
    data: JSON.stringify(values),
    sell_process_id: sellProcessId
  };
  const response = await fetchWithToken('/sell', { method: 'PUT', body: urlEncodeBody(data) });
  const jsonData = await response.json();
  if (!jsonData.update_or_insert) throw Error('Form submission failed.');
}

export async function obtainMlsId(address: string): Promise<string> {
  const response = await fetchWithToken(`listings/obtain_mls_id?address=${address}`);
  const data = await response.json();
  // console.log('obtainMlsId data', address, data);
  return data.listing_id;
}

export async function getSellprocessData(sellprocessId: string): Promise<any> {
  const response = await fetchWithToken(`/sell/${sellprocessId}`);
  const data = await response.json();
  // console.log('getSellprocessData data', sellprocessId, data);
  return data;
}
