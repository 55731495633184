import React from 'react';
import propertyDetailsSchema from '../formSchema/propertyDetailsSchema.json';

interface PropertyDetailsSummaryProps {
  data: { [key: string]: any; };
  onEdit: (value: SellProcessTabKey) => void;
}

const PropertyDetailsSummary: React.FC<PropertyDetailsSummaryProps> = ({ data, onEdit }) => {
  const renderCheckboxes = group => {
    const formKeys = Object.keys(propertyDetailsSchema[group]);
    const items = formKeys.filter(key => Boolean(data[key]));

    if (!items.length) return <p className="review-text">N/A</p>;

    return items.map(item => (
      <p className="review-text" key={item}>
        {propertyDetailsSchema[group][item].label}
      </p>
    ));
  };

  return (
    <div className="review-wrapper">
      <div className="review-header">
        <h3 className="review-title">Property Details:</h3>
        <button className="info-block__edit-btn" onClick={() => onEdit('details')}>
          Edit
        </button>
      </div>
      <div className="review-block">
        <div className="review-info-block">
          <p className="review-text">
            <strong>Available on: </strong>
            {data.available_on || 'N/A'}
          </p>
          <p className="review-text">
            <strong>Purchased on: </strong>
            {data.purchased_on || 'N/A'}
          </p>
          <p className="review-text">
            <strong>Community: </strong>
            {data.community || 'N/A'}
          </p>
          <p className="review-text">
            <strong>Property Tax ID: </strong>
            {data.property_tax_id || 'N/A'}
          </p>
          <p className="review-text">
            <strong>Uploaded Photos: </strong>
            {data.property_images?.length ? 'Yes' : 'No'}
          </p>
        </div>
        <div className="review-info-block">
          <p className="review-text">
            <strong>Property Description: </strong> {data.property_description || 'N/A'}
          </p>
        </div>
      </div>
      <div className="review-block">
        <div className="review-info-block">
          <h3 className="review-info-block__title">Appliances:</h3>
          {renderCheckboxes('appliances')}
        </div>
        <div className="review-info-block">
          <h3 className="review-info-block__title">Laundry:</h3>
          {data.laundry || 'N/A'}
        </div>
        <div className="review-info-block">
          <h3 className="review-info-block__title">Indoor Features:</h3>
          {renderCheckboxes('indoor_features')}
        </div>
        <div className="review-info-block">
          <h3 className="review-info-block__title">Outdoor Features:</h3>
          {renderCheckboxes('outdoor_features')}
        </div>
        <div className="review-info-block">
          <h3 className="review-info-block__title">Facilites & Recreation:</h3>
          {renderCheckboxes('facilities_and_recreation')}
        </div>
        <div className="review-info-block">
          <h3 className="review-info-block__title">Access & Security:</h3>
          {renderCheckboxes('access_and_security')}
        </div>
        <div className="review-info-block">
          <h3 className="review-info-block__title">Pets:</h3>
          {renderCheckboxes('pets')}
        </div>
      </div>
      <div className="review-block">
        <div className="review-block">
          <div className="review-info-block">
            <p className="review-text">
              <strong>Parking Spaces:</strong> {data.parking_spaces || 'N/A'}
            </p>
            <p className="review-text">
              <strong>Parking Number:</strong> {data.parking_number || 'N/A'}
            </p>
            <p className="review-text">
              <strong>Parking Information:</strong> {data.parking_info || 'N/A'}
            </p>
            <p className="review-text">
              <strong>Storage Information:</strong> {data.storage_info || 'N/A'}
            </p>
            <p className="review-text">
              <strong>Year of Construction:</strong> {data.year_built || 'N/A'}
            </p>
            <p className="review-text">
              <strong>Utility Features:</strong> {data.utility_features || 'N/A'}
            </p>
            <p className="review-text">
              <strong>A/C / Heater System:</strong> {data.ac_heater || 'N/A'}
            </p>
            <p className="review-text">
              <strong>Storage Number:</strong> {data.storage_number || 'N/A'}
            </p>
            <p className="review-text">
              <strong>Flooring Type:</strong> {data.flooring_type || 'N/A'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetailsSummary;
