import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { useQueryParam } from 'use-query-params';

import notify from 'notify';
import { AuthContext, AuthModalContext } from 'auth';
import { getListingDetails } from 'api/listings';
import { tryGetFirstError } from 'utils/requests';
import { submitSellProcessForm, obtainMlsId, getSellprocessData } from './api';

interface SellProcessContextValues {
  loading: boolean;
  activeTab: SellProcessTabKey;
  setActiveTab: React.Dispatch<React.SetStateAction<SellProcessTabKey>>;
  listing?: ListingDetails;
  sellprocessId: string;
  identifyTabData?: Partial<{ [key: string]: any; }>;
  setIdentifyTabData: React.Dispatch<React.SetStateAction<{ [key: string]: any; } | undefined>>;
  propertyInfoInit?: PropertyInfo;
  propertyInfoTabData?: { [key: string]: any; };
  setPropertyInfoTabData: React.Dispatch<React.SetStateAction<{ [key: string]: any; } | undefined>>;
  propertyDetailsTabData?: { [key: string]: any; };
  setPropertyDetailsTabData: React.Dispatch<
    React.SetStateAction<{ [key: string]: any; } | undefined>
  >;
  financialsTabData?: { [key: string]: any; };
  setFinancialsTabData: React.Dispatch<React.SetStateAction<{ [key: string]: any; } | undefined>>;
  formSubmitted: boolean;
  onFormSubmit: (value: { [key: string]: any; }) => void;
  listingSelected: boolean;
  setListingSelected: React.Dispatch<React.SetStateAction<boolean>>;
  showAuthModal: boolean;
  mlsListingLoading: boolean;
}

const SellProcessContext = React.createContext({} as SellProcessContextValues);

export const SellProcessContextWrapper = ({ children }) => {
  const location = useLocation();
  const [sellprocessId = ''] = useQueryParam<string>('id');
  const propertyInfoInit: PropertyInfo | undefined = (location.state as any)?.propertyInfo;
  const [activeTab, setActiveTab] = useState<SellProcessTabKey>('identify');
  const [loading, setLoading] = useState(Boolean(sellprocessId));
  const [mlsListingLoading, setMlsListingLoading] = useState(false);
  const [listing, setListing] = useState<ListingDetails>();
  const [listingSelected, setListingSelected] = useState(false);
  const [identifyTabData, setIdentifyTabData] = useState<{ [key: string]: any; }>();
  const [propertyInfoTabData, setPropertyInfoTabData] = useState<{ [key: string]: any; }>();
  const [propertyDetailsTabData, setPropertyDetailsTabData] = useState<{ [key: string]: any; }>();
  const [financialsTabData, setFinancialsTabData] = useState<{ [key: string]: any; }>();
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const { authorized } = useContext(AuthContext);
  const { setForceOpen } = useContext(AuthModalContext);

  const getListingByAddress = useCallback(async address => {
    setMlsListingLoading(true);
    try {
      const listingId = await obtainMlsId(address);
      const listingData = await getListingDetails(listingId);
      setListing(listingData);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(`Listing not found`);
    }
    setMlsListingLoading(false);
  }, []);

  const getListing = useCallback(async listingId => {
    try {
      const listing = await getListingDetails(listingId);
      setListing(listing);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(`Listing not found`);
    }
  }, []);

  useEffect(() => {
    if (!sellprocessId) {
      navigate('/', { replace: true });
      return;
    }
    if (!authorized) return;
    (async () => {
      setLoading(true);
      try {
        const sellprocessData = await getSellprocessData(sellprocessId);
        const address = sellprocessData.propertyAddress;
        setIdentifyTabData(prev => ({ ...prev, address1: address }));
        if (sellprocessData.propertyMlsId) getListing(sellprocessData.propertyMlsId);
        else if (sellprocessData.propertyMlsId === undefined) getListingByAddress(address);
      } catch (err) {
        if (err?.status === 417) {
          navigate('/404?expired');
        } else {
          notify((err.response && String(err.response)) || err.message);
        }
      }
      setLoading(false);
    })();
  }, [getListingByAddress, sellprocessId, authorized, getListing]);

  const onFormSubmit = async () => {
    delete identifyTabData?.autocompleteAddress;
    const formValues = {
      ...identifyTabData,
      ...propertyInfoTabData,
      ...propertyDetailsTabData,
      ...financialsTabData,
      propertyPhoto: listing?.images?.[0]
    };
    try {
      await submitSellProcessForm(formValues, sellprocessId);
      setFormSubmitted(true);
    } catch (err) {
      notify(tryGetFirstError(err) || err.message);
    }
  };

  useEffect(() => {
    if (!authorized) setForceOpen(true);
  }, [authorized, setForceOpen]);

  const contextValues = {
    loading,
    mlsListingLoading,
    activeTab,
    setActiveTab,
    listing,
    sellprocessId,
    identifyTabData,
    setIdentifyTabData,
    propertyInfoInit,
    propertyInfoTabData,
    setPropertyInfoTabData,
    propertyDetailsTabData,
    setPropertyDetailsTabData,
    financialsTabData,
    setFinancialsTabData,
    formSubmitted,
    onFormSubmit,
    listingSelected,
    setListingSelected,
    showAuthModal: Boolean(!authorized)
  };

  return (
    <SellProcessContext.Provider value={contextValues}>{children}</SellProcessContext.Provider>
  );
};

export default SellProcessContext;
